/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { Children } from 'react';

import { STOP_SLIDE_COUNT } from 'Component/AdditionalPictures/AdditionalPictures.config';
import Draggable from 'Component/Draggable';
import { ANIMATION_DURATION } from 'Component/Slider/Slider.config';
import { Slider as SourceSlider } from 'SourceComponent/Slider/Slider.component';
import { ChildrenType, MixType } from 'Type/Common.type';
import { DeviceType } from 'Type/Device.type';
import { isArabic } from 'Util/Arabic';
import CSS from 'Util/CSS';

import {
    ACTIVE_SLIDE_PERCENT,
    SLIDE_TRANSFORM_CLICK_LIMIT,
    START_SLIDE_COUNT
} from './Slider.config';

import './Slider.extended.style';

/** @namespace Scandipwa/Component/Slider/Component */
export class SliderComponent extends SourceSlider {
    static propTypes = {
        showCrumbs: PropTypes.bool,
        showArrows: PropTypes.bool,
        activeImage: PropTypes.number,
        onActiveImageChange: PropTypes.func,
        mix: MixType,
        children: ChildrenType.isRequired,
        isInteractionDisabled: PropTypes.bool,
        device: DeviceType.isRequired,
        isPDPSlider: PropTypes.bool,
        isSliderOverlay: PropTypes.bool
    };

    static defaultProps = {
        activeImage: 0,
        onActiveImageChange: () => {},
        showCrumbs: false,
        showArrows: false,
        isInteractionDisabled: false,
        mix: {},
        isPDPSlider: false,
        isSliderOverlay: false
    };

    __construct(props) {
        super.__construct(props);

        const { activeImage } = this.props;

        this.state = {
            prevActiveImage: activeImage
        };
    }

    updateWindowDimensions = this.updateWindowDimensions.bind(this);

    componentDidMount() {
        const { isPdpDesktopSlider } = this.props;

        super.componentDidMount();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        if (isPdpDesktopSlider) {
            window.addEventListener('wheel', this.handleScroll, { passive: false });
        }
    }

    componentDidUpdate(prevProps) {
        const { activeImage: prevActiveImage, isDesktop: prevIsDesktop } = prevProps;
        const { activeImage, isDesktop } = this.props;

        const sliderWidth = isDesktop !== prevIsDesktop ? this.draggableRef.current?.offsetWidth : this.sliderWidth;
        const newTranslate = -activeImage * sliderWidth;
        const prevTranslate = -prevActiveImage * sliderWidth;

        if (isDesktop !== prevIsDesktop) {
            this.updateDraggableRefStyles(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION), newTranslate);

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ lastTranslateX: prevTranslate });

            this.sliderWidth = sliderWidth;
        }

        if (activeImage !== prevActiveImage) {
            this.updateDraggableRefStyles(Math.abs((prevActiveImage - activeImage) * ANIMATION_DURATION), newTranslate);
        }
    }

    componentWillUnmount() {
        const { isPdpDesktopSlider } = this.props;

        window.removeEventListener('resize', this.updateWindowDimensions);

        if (isPdpDesktopSlider) {
            window.removeEventListener('wheel', this.handleScroll);
        }
    }

    handleScroll = (e) => {
        const currentSliderRef = this.sliderRef.current;

        if (Math.abs(e.deltaY) < 10) {
            return;
        }

        if (currentSliderRef) {
            e.preventDefault();
            const windowScrollY = window.scrollY;

            if (
                e.deltaY > 0
                && windowScrollY > 100
                && currentSliderRef.scrollTop < currentSliderRef.scrollHeight - currentSliderRef.clientHeight
            ) {
                currentSliderRef.scrollBy(0, 25);
            } else if (
                e.deltaY < 0
                && windowScrollY < 300
                && currentSliderRef.scrollTop > 0
            ) {
                currentSliderRef.scrollBy(0, -25);
            } else if (e.deltaY > 0) {
                scrollBy(0, 25);
            } else {
                scrollBy(0, -25);
            }
        }
    };

    updateWindowDimensions() {
        if (!this.draggableRef.current) {
            return;
        }

        const sliderWidth = this.draggableRef.current.offsetWidth;
        this.sliderWidth = sliderWidth;
    }

    updateDraggableRefStyles(animationSpeed, translateX) {
        CSS.setVariable(
            this.draggableRef,
            'animation-speed',
            `${ animationSpeed }ms`
        );

        CSS.setVariable(
            this.draggableRef,
            'translateX',
            `${ isArabic() ? Math.abs(translateX) : translateX }px`
        );
    }

    calculateNextSlide(state) {
        const {
            translateX: translate,
            lastTranslateX: lastTranslate
        } = state;

        const { onActiveImageChange } = this.props;

        const slideSize = this.sliderWidth;

        const fullSliderSize = this.getFullSliderWidth();

        if (isArabic()) {
            const activeSlidePosition = -translate / slideSize;
            const activeSlidePercent = Math.abs(activeSlidePosition % 1);
            const isSlideBack = -translate > -lastTranslate;

            if (translate <= 0) {
                onActiveImageChange(0);
                return 0;
            }

            if (translate > fullSliderSize) {
                const activeSlide = Math.round(fullSliderSize / -slideSize);
                onActiveImageChange(activeSlide);

                return -activeSlide;
            }

            if (isSlideBack && activeSlidePercent < 1 - ACTIVE_SLIDE_PERCENT) {
                const activeSlide = Math.ceil(activeSlidePosition);
                onActiveImageChange(activeSlide);

                return -activeSlide;
            }

            if (!isSlideBack && activeSlidePercent > ACTIVE_SLIDE_PERCENT) {
                const activeSlide = Math.floor(activeSlidePosition);
                onActiveImageChange(activeSlide);

                return -activeSlide;
            }

            const activeSlide = Math.round(activeSlidePosition);
            onActiveImageChange(activeSlide);

            return -activeSlide;
        }

        const activeSlidePosition = translate / slideSize;
        const activeSlidePercent = Math.abs(activeSlidePosition % 1);
        const isSlideBack = translate > lastTranslate;

        if (translate >= 0) {
            onActiveImageChange(0);
            return 0;
        }

        if (translate < -fullSliderSize) {
            const activeSlide = Math.round(fullSliderSize / -slideSize);
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        if (isSlideBack && activeSlidePercent < 1 - ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.ceil(activeSlidePosition);
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        if (!isSlideBack && activeSlidePercent > ACTIVE_SLIDE_PERCENT) {
            const activeSlide = Math.floor(activeSlidePosition);
            onActiveImageChange(-activeSlide);

            return activeSlide;
        }

        const activeSlide = Math.round(activeSlidePosition);
        onActiveImageChange(-activeSlide);

        return activeSlide;
    }

    getNextImage(btnType, isProductCardSlider = false) {
        const { activeImage, children } = this.props;
        const absActiveImage = Math.abs(activeImage);

        // This is possible during the load.
        if (!children) {
            return absActiveImage;
        }

        const childrenCount = children.reduce((acc, child) => {
            if (child && child.length) {
                return acc + child.length;
            }

            return acc;
        }, 0);

        if (btnType === 'left') {
            // eslint-disable-next-line no-nested-ternary
            return isArabic()
                ? absActiveImage + 1
                : !absActiveImage ? absActiveImage : absActiveImage - 1;
        }

        if (!isProductCardSlider && absActiveImage === childrenCount - 1) {
            return absActiveImage;
        }

        return isArabic() ? absActiveImage - 1 : absActiveImage + 1;
    }

    renderButton(btnType, slideCount = START_SLIDE_COUNT, lastImage = false) {
        const {
            onActiveImageChange,
            children,
            activeImage,
            isProductCardSlider
        } = this.props;

        if (!children) {
            return null;
        }

        const image = this.getNextImage(btnType, isProductCardSlider);
        const childrenCount = children.reduce((acc, child) => {
            if (child && (child.length || Object.keys(child).length > 0)) {
                return child.length ? acc + child.filter((item) => item !== false).length : acc + 1;
            }

            return acc;
        }, 0);

        const noNextSlide = isArabic()
            ? (btnType === 'right' && Math.abs(activeImage) === 0)
            || (btnType === 'left' && (Math.abs(activeImage) === childrenCount - 1 || childrenCount === 0))
            : (btnType === 'left' && activeImage === 0)
            || (btnType === 'right' && (activeImage === childrenCount - 1 || childrenCount === 0));

        if (slideCount <= STOP_SLIDE_COUNT || lastImage) {
            return null;
        }

        if (isProductCardSlider) {
            return (
                <button
                  block="Slider"
                  elem="ArrowContainer"
                  mods={ { type: btnType, noNextSlide } }
                  onClick={ (e) => onActiveImageChange(image, e) }
                >
                    <div
                      block="Slider"
                      elem="Arrow"
                      mods={ { type: btnType, noNextSlide } }
                    >
                        <span>{ __('Change Slide') }</span>
                    </div>
                </button>
            );
        }

        return (
            <button
              block="Slider"
              elem="Arrow"
              mods={ { type: btnType, noNextSlide } }
              onClick={ () => onActiveImageChange(image) }
            >
                <span>{ __('Change Slide') }</span>
            </button>
        );
    }

    handleDrag(state) {
        const { translateX } = state;

        const fullSliderSize = this.getFullSliderWidth();

        if (isArabic() && translateX > 0 && translateX > -fullSliderSize) {
            CSS.setVariable(
                this.draggableRef,
                'translateX',
                `${Math.abs(translateX)}px`
            );
        } else if (translateX < 0 && translateX > -fullSliderSize) {
            CSS.setVariable(
                this.draggableRef,
                'translateX',
                `${translateX}px`
            );
        }
    }

    handleDragEnd(state, callback) {
        const { onSliderClick } = this.props;
        const { translateX, translateY } = state;

        // Consider small drag a click
        if ((Math.abs(translateX) < SLIDE_TRANSFORM_CLICK_LIMIT && Math.abs(translateY) < SLIDE_TRANSFORM_CLICK_LIMIT)
            && onSliderClick) {
            onSliderClick();
            return;
        }

        const activeSlide = this.calculateNextSlide(state);
        const slideSize = this.sliderWidth;
        const newTranslate = activeSlide * slideSize;

        CSS.setVariable(this.draggableRef, 'animation-speed', '300ms');

        CSS.setVariable(
            this.draggableRef,
            'translateX',
            `${ isArabic() ? Math.abs(newTranslate) : newTranslate }px`
        );

        callback({
            originalX: newTranslate,
            lastTranslateX: newTranslate
        });
    }

    renderCrumbs() {
        const { children } = this.props;
        if (children.length <= 1) {
            return null;
        }

        return (
            <div
              block="Slider"
              elem="Crumbs"
              dir={ isArabic() ? 'rtl' : 'ltr' }
            >
                { Children.map(children, this.renderCrumb) }
            </div>
        );
    }

    getIsSlider() {
        const { children, isPdpDesktopSlider } = this.props;

        return !isPdpDesktopSlider && children.length > 0;
    }

    renderPdpDesktopSlider() {
        const { onSliderClick, children: childrenRaw } = this.props;

        const children = childrenRaw.flat();

        return (
            <div
              block="PdpDesktopImages"
              ref={ this.sliderRef }
              onClick={ onSliderClick }
              style={ { '--image-height': `${this.sliderRef?.current?.clientWidth / 2 }px` } }
            >
                { children }
            </div>
        );
    }

    render() {
        const {
            showCrumbs,
            showArrows,
            mix,
            activeImage,
            children: childrenRaw,
            sliderOverlayWidth,
            isSliderOverlay,
            device,
            isPdpDesktopSlider
        } = this.props;

        if (isPdpDesktopSlider) {
            return this.renderPdpDesktopSlider();
        }

        const children = childrenRaw.flat();
        const sliderWidth = sliderOverlayWidth !== 0 ? sliderOverlayWidth : this.sliderWidth;

        return (
            <>
                { showArrows && (isArabic() ? this.renderButton('right') : this.renderButton('left')) }
                <div
                  block="Slider"
                  mix={ mix }
                  ref={ this.sliderRef }
                >
                    <Draggable
                      mix={ { block: 'Slider', elem: 'Wrapper' } }
                      draggableRef={ this.draggableRef }
                      onDragStart={ this.handleDragStart }
                      onDragEnd={ this.handleDragEnd }
                      onDrag={ this.handleDrag }
                      shiftX={ -activeImage * sliderWidth }
                      isSliderOverlay={ isSliderOverlay }
                      isMobile={ device.isMobile }
                      useDir
                    >
                        { children }
                    </Draggable>
                    { showCrumbs && this.renderCrumbs() }
                </div>
                { showArrows && (isArabic() ? this.renderButton('left') : this.renderButton('right')) }
            </>
        );
    }
}

export default SliderComponent;
